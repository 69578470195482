import React from "react";
import Layout from "../components/layout";
import { LiveStream } from "../components/livestream";
import { AddToCal } from "../components/AddToCalendar";
import { RsvpForm } from "../components/RsvpForm";

const date = new Date("9/12/2021 11:00 GMT-0500");
const dayOf = new Date("9/12/2021 00:00 GMT-0500");
const isToday = new Date() >= dayOf;

export const ELnU0n5ozMk = () => {
  return (
    <Layout date={date} isToday={isToday}>
      {isToday ? (
        <LiveStream />
      ) : (
        <div style={{ maxWidth: 500, margin: "auto", padding: "0 8px" }}>
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link
            rel="preconnect"
            href="https://fonts.gstatic.com"
            crossOrigin="true"
          />
          <link
            href="https://fonts.googleapis.com/css2?family=Dancing+Script&display=swap"
            rel="stylesheet"
          />
          <AddToCal />
          <br />
          <RsvpForm />
          <a href="https://www.myregistry.com/wedding-registry/janelle-king-and-shawn-naeser-lubbock-tx/2913510/giftlist"
            target="_blank"
            rel="noopener noreferrer" className="cal-btn">Shop Registry</a>
        </div>
      )}
    </Layout>
  );
};

export default ELnU0n5ozMk;
