import React from "react";

export const RsvpForm = () => {
  React.useEffect(() => {
    window.fnames = [];
    window.ftypes = [];
    window.fnames[0] = "EMAIL";
    window.ftypes[0] = "email";
    window.fnames[1] = "FNAME";
    window.ftypes[1] = "text";
    window.fnames[2] = "LNAME";
    window.ftypes[2] = "text";
    window.fnames[3] = "ADDRESS";
    window.ftypes[3] = "address";
    window.fnames[4] = "PHONE";
    window.ftypes[4] = "phone";
    window.fnames[5] = "BIRTHDAY";
    window.ftypes[5] = "birthday";
  }, []);

  return (
    <>
      <div id="mc_embed_signup" style={{ margin: "16px 0 32px" }}>
        <form
          action="https://jkingworking.us6.list-manage.com/subscribe/post?u=415c606586b69971a670153cc&amp;id=d5668ff08a"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <div id="mc_embed_signup_scroll">
            <div className="mc-field-group">
              <label htmlFor="mce-EMAIL">
                Enter your email address to be reminded on the day of the
                wedding
              </label>

              <input
                type="email"
                name="EMAIL"
                className="required email"
                id="mce-EMAIL"
                placeholder="you@email.com"
              />

              <input
                type="submit"
                value="Be Reminded"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="send-btn"
              />
            </div>
            <div id="mce-responses" className="clear">
              <div
                className="response"
                id="mce-error-response"
                style={{ display: "none" }}
              />
              <div
                className="response"
                id="mce-success-response"
                style={{ display: "none" }}
              />
            </div>
            <br />
            <br />
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_415c606586b69971a670153cc_d5668ff08a"
                tabIndex="-1"
                value=""
              />
            </div>
          </div>
        </form>
      </div>
      <script
        type="text/javascript"
        src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"
      ></script>
    </>
  );
};
