import React from "react";

export const AddToCal = ({ id, time, gmailTemplate }) => (
  <div style={{ marginTop: 32, textAlign: "center" }}>
    <h3>The LIVE stream will begin @ {time}.</h3>
    <div className="cal-btn-table">
      <div style={{ flex: "0,1,0" }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={`/${id}.ics`}
          className="cal-btn"
        >
          Add to iCal
        </a>
      </div>
      <div style={{ flex: "0,1,0" }}>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={gmailTemplate}
          className="cal-btn"
        >
          Add to Google Calendar
        </a>
      </div>
    </div>
  </div>
);
